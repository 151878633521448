@font-face {
  font-family: 'Circular';
  src: url('Circular-Book.woff2') format('woff2'),
    url('Circular-Book.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular';
  src: url('CircularStd-Black.woff2') format('woff2'),
    url('CircularStd-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular';
  src: url('CircularAir-Light.woff2') format('woff2'),
    url('CircularAir-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular';
  src: url('Circular-Bold.woff2') format('woff2'),
    url('Circular-Bold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular';
  src: url('Circular-MediumItalic.woff2') format('woff2'),
    url('Circular-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Circular';
  src: url('Circular-BookItalic.woff2') format('woff2'),
    url('Circular-BookItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Circular';
  src: url('Circular-Medium.woff2') format('woff2'),
    url('Circular-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
