@font-face {
  font-family: 'Basier Circle';
  src: url('BasierCircle-SemiBoldItalic.woff2') format('woff2'),
    url('BasierCircle-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Basier Circle';
  src: url('BasierCircle-BoldItalic.woff2') format('woff2'),
    url('BasierCircle-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Basier Circle';
  src: url('BasierCircle-SemiBold.woff2') format('woff2'),
    url('BasierCircle-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Basier Circle';
  src: url('BasierCircle-Italic.woff2') format('woff2'),
    url('BasierCircle-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Basier Circle';
  src: url('BasierCircle-Bold.woff2') format('woff2'),
    url('BasierCircle-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Basier Circle';
  src: url('BasierCircle-MediumItalic.woff2') format('woff2'),
    url('BasierCircle-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Basier Circle';
  src: url('BasierCircle-Medium.woff2') format('woff2'),
    url('BasierCircle-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Basier Circle';
  src: url('BasierCircle-Regular.woff2') format('woff2'),
    url('BasierCircle-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
